* {
  font-family: 'Inter', sans-serif;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}
.scPage::-webkit-scrollbar {
  display: none!important;
  width: 0!important;
}
.scPage {
  scrollbar-width: none!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none;
  resize: none!important;
}
input, textarea {
  border: none;
}
input:focus, textarea:focus {
  outline: 2px solid #2491ff;
  transition: 300ms ease-in-out;
}
input::placeholder, textarea::placeholder {
  color: #000;
}
.sc::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.sc::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(98, 98, 98/1);
}
.sc::-webkit-scrollbar-track {
  background-color: rgb(56, 56, 56/1);
  border-radius: 10px;
}

